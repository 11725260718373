export const brandPagePaths = [
  {
    id: 1,
    path: `/slowbread/`,
    text: "SLOW BREAD",
  },
  {
    id: 2,
    path: `/naturel/`,
    text: "ナチュレル",
  },
  {
    id: 3,
    path: `/naturel-bread/`,
    text: "ナチュレル(食パン)",
  },
  // {
  //   id: 4,
  //   path: `/francois-bread/`,
  //   text: "ずっと天然素材食パン",
  // },
  {
    id: 5,
    path: `/variety-bread/`,
    text: "バラエティ食パン",
  },
  // {
  //   id: 6,
  //   path: `/omochiya/`,
  //   text: "おもち家",
  // },
  {
    id: 7,
    path: `/apelle/`,
    text: "Apelle",
  },
  {
    id: 8,
    path: `/we/`,
    text: "We",
  },
  {
    id: 9,
    path: `/deli/`,
    text: "DELI",
  },
  {
    id: 10,
    path: `/sweets-sweets/`,
    text: "スイーツ・スイーツ",
  },
  {
    id: 11,
    path: `/hitokuchido/`,
    text: "一口堂",
  },
]

export const pagePath = {
  thisMonthProducts: {
    id: 1,
    path: `/thismonth-products/`,
    text: "今月の新商品",
    text2: "新商品一覧",
  },

  productSearch: {
    id: 2,
    path: `/product-search/`,
    text: "商品検索",
  },
  allergenSearch: {
    id: 3,
    path: `/allergen-search/`,
    text: "アレルゲン検索",
  },
  ourPolicy: {
    id: 4,
    path: `/approach/`,
    text: "おいしさと健康は素材から",
  },
  qaOnRawMaterials: {
    id: 5,
    path: `/qa-on-raw-materials-and-additives/`,
    text: "原料・添加物に関する Q&A",
  },
  recipeBook: {
    id: 6,
    path: `/recipe-book/`,
    text: "フランソア商品のアレンジメニュー",
  },
  cmYoutube: {
    id: 7,
    path: `/cm-youtube/`,
    text: "CM集",
  },
  inquiry: {
    id: 8,
    path: `/inquiry/`,
    text: "商品に関するお問合せ",
  },
  faq: {
    id: 9,
    path: `/inquiry/faq-page`,
    text: "よくあるご質問",
  },
  privacyPolicy: {
    id: 10,
    path: `/privacy-policy`,
    text: "プライバシーポリシー",
  },
  ourPolicyCommitment: {
    id: 11,
    path: `/approach/commitment`,
    text: "私達のこだわり",
  },
  ourPolicyFiber: {
    id: 12,
    path: `/approach/fiber`,
    text: "食物繊維について",
  },
  ourPolicyWalnuts: {
    id: 13,
    path: `/approach/walnut.html`,
    text: "自家焙煎くるみについて",
  },
}

export const externalLinkPath = {
  corporateHome: {
    id: 1,
    path: "https://corporate.francois.co.jp/",
    text: "",
  },
  corporateInquiry: {
    id: 2,
    path: "https://corporate.francois.co.jp/inquiry",
    text: "会社に関するお問合わせ",
  },
  slowbread: {
    id: 3,
    path: "http://www.slowbread.jp/",
  },
  soygood: {
    id: 4,
    path: "https://soygood.jp/",
  },
  kurashi: {
    id: 5,
    path: "https://kurashi.online/",
  },
  corporatePage: {
    id: 6,
    path: "https://corporate.francois.co.jp/",
    text: "フランソアコーポレートサイト",
  },
  corporateInfo: {
    id: 7,
    path: "https://corporate.francois.co.jp/",
    text: "お知らせ",
  },
  corporateCompanyProfiles: {
    id: 8,
    path: "https://corporate.francois.co.jp/company-profiles/",
    text: "企業情報",
  },
  recruit: {
    id: 9,
    path: "https://corporate.francois.co.jp/recruitment/",
    text: "採用情報",
  },
  recruitSpecial: {
    id: 10,
    path: "https://recruit.francois-group.jp/",
    text: "リクルート特設サイト",
  },
  sdgs: {
    id: 11,
    path: "https://sdgs.francois-group.jp/",
    text: "SDGs特設サイト",
  },
}
